import React, { useCallback, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import clipboardCopy from 'clipboard-copy';
import dayjs from 'dayjs';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { tournamentsClient } from 'src/apollo/client';
import { useOrgId, useOrgName } from 'src/apollo/local-state';
import APIErrorMessage from 'src/components/api-error-message/api-error-message';
import InfoPanel, { Props as ActionButtonProps, InfoNugget } from 'src/components/info-panel/info-panel';
import ButtonModal from 'src/components/modal/modal';
import PageHeader from 'src/components/page-header/page-header';
import SafePlayStatus from 'src/components/safeplay-status/safeplay-status';
import { RegisteredStatus } from 'src/components/tournament-director-attributes/tournament-director-attributes';
import { INVITE_TOURNAMENT_DIRECTOR } from 'src/components/tournament-director-invite/tournament-director-invite-queries';
import { clearOCRs } from 'src/components/tournament-directors/tournament-directors-queries';
import { BodyRegular } from 'src/components/typography/typography';
import { getClientConfig } from 'src/config/config';
import { AddTournamentDirector, AddTournamentDirectorVariables } from 'src/graphql-types/AddTournamentDirector';
import { GetTournamentDirector, GetTournamentDirectorVariables } from 'src/graphql-types/GetTournamentDirector';
import {
  RemoveTournamentDirector,
  RemoveTournamentDirectorVariables,
} from 'src/graphql-types/RemoveTournamentDirector';

import { BodyRegularBold } from '@clubspark-react/clubspark-react-tools';

import { GET_TOURNAMENT_DIRECTOR, REMOVE_TOURNAMENT_DIRECTOR } from './tournament-director-profile-queries';

interface Props {
  directorId?: string;
}

const DirectorProfile: React.FC<Props> = ({ directorId }) => {
  const { t } = useTranslation();
  const orgId = useOrgId();

  const { data, loading, error } = useQuery<GetTournamentDirector, GetTournamentDirectorVariables>(
    GET_TOURNAMENT_DIRECTOR,
    { variables: { id: directorId }, client: tournamentsClient },
  );

  const [resendInvite, { data: resendData, loading: resendLoading, error: resendError }] = useMutation<
    AddTournamentDirector,
    AddTournamentDirectorVariables
  >(INVITE_TOURNAMENT_DIRECTOR, { client: tournamentsClient });

  const ocr = data?.organisationContactRelationship;
  const contact = useMemo(() => {
    if (ocr?.type === 'DIRECTOR') {
      return ocr.contact;
    }
    return null;
  }, [ocr]);

  const [copied, setCopied] = useState(false);

  const onCopy = useCallback(() => {
    if (ocr?.invitation?.url) {
      clipboardCopy(ocr?.invitation?.url);
      setCopied(true);
    }
  }, [setCopied, ocr]);

  const onResend = useCallback(() => {
    if (ocr?.invitation?.externalId) {
      resendInvite({
        variables: { contact: { externalId: ocr.invitation.externalId, organisationId: orgId } },
      });
    }
  }, [ocr, resendInvite, orgId]);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const onDelete = useCallback(() => {
    setShowDeleteModal(true);
  }, []);

  const actionButtons = useMemo<ActionButtonProps['actionButtons']>(() => {
    if (ocr?.invitation?.url && !ocr.contact?.isRegistered) {
      const { expiryDate, externalId } = ocr.invitation;
      if (expiryDate && dayjs(expiryDate).isBefore(dayjs()) && externalId) {
        return [
          {
            title: t('delete'),
            onClick: onDelete,
            loading: resendLoading,
            level: 'warning',
          },
          {
            title: resendData ? t('invite resent') : t('invite expired resend'),
            icon: resendData ? 'md-tick' : 'md-clock-end',
            onClick: onResend,
            loading: resendLoading,
            level: 'secondary',
          },
        ];
      }
      return [
        {
          title: t('delete'),
          onClick: onDelete,
          loading: resendLoading,
          level: 'warning',
        },
        {
          title: !copied ? t('copy invite link') : t('copied to clipboard'),
          icon: 'md-link',
          level: 'secondary',
          onClick: onCopy,
        },
      ];
    } else
      return [
        {
          title: t('delete'),
          onClick: onDelete,
          loading: resendLoading,
          level: 'warning',
        },
      ];
  }, [ocr, copied, onCopy, onResend, resendLoading, resendData, t]);

  const [removeDirector, { loading: removing, error: removingError }] = useMutation<
    RemoveTournamentDirector,
    RemoveTournamentDirectorVariables
  >(REMOVE_TOURNAMENT_DIRECTOR, { client: tournamentsClient });

  const handleRemove = useCallback(async () => {
    try {
      await removeDirector({
        variables: { id: ocr?.id },
        update: clearOCRs,
      });
      navigate('/tournaments/directors');
    } catch (error) {
      setShowDeleteModal(false);
    }
  }, [ocr, removeDirector]);

  return (
    <>
      <PageHeader title={t('tournament director')} />
      <APIErrorMessage error={error?.message} />
      {(loading || contact) && (
        <>
          <InfoPanel
            loading={loading}
            title={`${contact?.firstName} ${contact?.lastName}`}
            subtitle={contact?.emailAddress ?? t('n/a')}
            actionButtons={actionButtons}
          >
            <InfoNugget title={t('status')}>{ocr && <RegisteredStatus director={ocr} />}</InfoNugget>
            {getClientConfig().safeplay && (
              <InfoNugget title={t('safe play')}>
                <SafePlayStatus status={contact?.safePlay?.status} />
              </InfoNugget>
            )}
            <InfoNugget title={t('telephone')}>{contact?.phoneNumber || t('n/a')}</InfoNugget>
          </InfoPanel>
          {removingError && <APIErrorMessage error={removingError.message} />}
          {resendError && <APIErrorMessage error={resendError.message} />}
        </>
      )}
      <ButtonModal
        actionButtons={[
          {
            id: 'delete',
            content: t('delete'),
            disableAutoClose: true,
            props: { level: 'warning', type: 'submit', loading: removing, onClick: handleRemove },
          },
        ]}
        show={showDeleteModal}
        cancelButton
        title={t('remove td')}
        openButton={{ hidden: true }}
        onVisibilityChange={setShowDeleteModal}
        hideCloseButton
      >
        <DeleteModal />
      </ButtonModal>
    </>
  );
};

const DeleteModal = () => {
  const { t } = useTranslation();
  const venue = useOrgName();
  return (
    <>
      <BodyRegular>{t('remove td text', { venue })}</BodyRegular>
      <BodyRegularBold spacing={{ margins: { md: 'top' } }}>{t('remove td question')}</BodyRegularBold>
    </>
  );
};

export default DirectorProfile;
