import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';
import DirectorProfile from 'src/components/tournament-director-profile/tournament-director-profile';

const TournamentDirectorProfile = () => {
  return (
    <Router basepath="/tournaments/directors">
      <DirectorProfileRoute path="/:directorId" />
    </Router>
  );
};

interface DirectorProfileProps extends RouteComponentProps {
  directorId?: string;
}

const DirectorProfileRoute: React.FC<DirectorProfileProps> = ({ directorId }) => {
  return (
    <Layout>
      <SEO title="Tournament Director Profile" />
      <PrivateRoute>
        <DirectorProfile directorId={directorId} />
      </PrivateRoute>
    </Layout>
  );
};

export default TournamentDirectorProfile;
